header {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  header .logo img {
    max-width: 150px;
  }
  
  .cotizacion-header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
    position: relative;
  }
  
  .yellow-bar-left,
  .yellow-bar-right {
    height: 20px;
    background-color: #ffc107;
    flex-grow: 1;
  }
  
  .yellow-bar-left {
    margin-right: 10px;
  }
  
  .yellow-bar-right {
    margin-left: 10px;
  }
  
  .cotizacion-title {
    font-size: 36px;
    font-weight: bold;
    color: black;
    margin: 0;
    padding: 5px 10px;
    background-color: white;
    z-index: 1;
    position: relative;
    flex-shrink: 0;
  }
  
  .cliente-info {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 40px;
    margin-bottom: 20px;
  }
  
  .left-info,
  .right-info {
    flex: 1;
    min-width: 45%;
  }
  
  .items-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
  }
  
  .items-table th,
  .items-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  .items-table th {
    background-color: #333;
    color: #fff;
  }
  
  .status-select {
    padding: 5px;
    border-radius: 5px;
    border: none;
    color: white;
    font-weight: bold;
    cursor: pointer;
  }
  